import React, { useState } from 'react';

const Test2Home = () => {
  const [input, setInput] = useState("");

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  // Split the input into lines and filter out empty ones
  const urls = input.split('\n').filter((url) => url.trim() !== "");

  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <h1>Deep Link Tester</h1>
      <textarea
        placeholder="Enter URLs, one per line..."
        value={input}
        onChange={handleInputChange}
        style={{
          width: '100%',
          height: '150px',
          padding: '10px',
          marginBottom: '20px',
          fontSize: '16px',
        }}
      />
      <div>
        <h2>Generated Links</h2>
        {urls.map((url, index) => (
          <div key={index}>
            <a href={url} style={{ color: 'blue', textDecoration: 'underline' }}>
              {url}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Test2Home;