import React from 'react';

const PrivacyPolicy = () => (
  <div style={{ margin: '20px', lineHeight: '1.6' }}>
    <h1>Privacy Policy for Schmusi's Purrfect Pairs</h1>
    <p>Last updated: 24. Mar 2024</p>

    <h2>Introduction</h2>
    <p>Thank you for choosing to use Schmusi's Purrfect Pairs Game. We take your privacy seriously and are committed to protecting your personal information. This Privacy Policy outlines the types of information we collect, or don't collect, and how we use, or don't use, that information.</p>
    
    <h2>Information We Collect</h2>
    <p>We do not collect any personal information from you when you use Schmusi's Purrfect Pairs.</p>
    
    <h2>How We Use Information</h2>
    <p>Since we do not collect any personal information, we do not use any personal information either.</p>
    
    <h2>Third-Party Services</h2>
    <p>Schmusi's Purrfect Pairs does not use third-party services that collect information used to identify you.</p>

    <h2>Security</h2>
    <p>While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security, as no method of electronic storage is 100% secure. However, since we do not collect any personal information, there is no personal information to secure.</p>

    <h2>Disclaimer</h2>
    <p>Schmusi's Purrfect Pairs is provided "AS IS" without warranty of any kind, either express or implied, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose, or non-infringement.</p>

    <h2>Changes to This Privacy Policy</h2>
    <p>We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately after they are posted on this page.</p>

    <h2>Contact Us</h2>
    <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at apps[at]superus8r[dot]com</p>
  </div>
);

export default PrivacyPolicy;