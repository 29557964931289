import React from 'react';

const TestUrls = () => (
  <div style={{ margin: '20px', lineHeight: '1.6' }}>
    <h1>Test URLs</h1>
    <p>Click to test:</p>

    <h2>1</h2>
    <p>
    <a href="rewe://shop">rewe://shop</a>
    </p>
    <p>
    <a href="https://shop.rewe.de/">https://shop.rewe.de/</a>
    </p>

    <p>
    <a href="https://shop.rewe.de/angebote">https://shop.rewe.de/angebote</a>
    </p>
    <p>
    <a href="https://shop.rewe.de/c/milch">https://shop.rewe.de/c/milch</a>
    </p>

    <h2>2</h2>
    <p>
    <a href="rewe://payback">rewe://payback</a>
    </p>
    <p>
    <a href="https://www.rewe.de/payback/">https://www.rewe.de/payback/</a>
    </p>
    <p>
    <a href="rewe://angebote">rewe://angebote</a>
    </p>
    <p>
    <a href="https://www.rewe.de/angebote">https://www.rewe.de/angebote</a>
    </p>
    <p>
    <a href="https://www.rewe.de/angebote/">https://www.rewe.de/angebote/</a>
    </p>
    <p>
    <a href="https://www.rewe.de/angebote/nationale-angebote/#5142873">https://www.rewe.de/angebote/nationale-angebote/#5142873</a>
    </p>
    <p>
    <a href="https://www.rewe.de/rezepte/">https://www.rewe.de/rezepte/</a>
    </p>
    <p>
    <a href="https://www.rewe.de/rezepte">https://www.rewe.de/rezepte</a>
    </p>
    <p>
    <a href="https://www.rewe.de/rezepte/lachsfilet-mit-hausfrauensosse/">https://www.rewe.de/rezepte/lachsfilet-mit-hausfrauensosse/</a>
    </p>
    <p>
    <a href="https://www.rewe.de/produkte/feldschloesschen-dunkles-winterbier-11x05-1/8384960/">https://www.rewe.de/produkte/feldschloesschen-dunkles-winterbier-11x05-1/8384960/</a>
    </p>
    <p>
    <a href="https://www.rewe.de/marktseite/koeln-dellbrueck/1940017/rewe-markt-dellbruecker-hauptstr-92-94/">https://www.rewe.de/marktseite/koeln-dellbrueck/1940017/rewe-markt-dellbruecker-hauptstr-92-94/</a>
    </p>

    <h2>3</h2>
    <p>
        <a href="rewe://web-content?title=Scheine%20f%C3%BCr%20Vereine&redirectUrl=https%3A%2F%2Fstage.p865c186.bloonix.cloud%3Fcode%3D1-2C9rg4KRDD1">rewe://web-content?title=Scheine für Vereine - Stage</a>
    </p>
    <p>
        <a href="rewe://web-content?title=Scheine%20f%C3%BCr%20Vereine&redirectUrl=https%3A%2F%2Fscheinefuervereine.rewe.de%3Fcode%3DV-28u22Dmemm1">rewe://web-content?title=Scheine für Vereine - Live</a>
    </p>

    <h2>4</h2>
    <p>
        <a href="https://rewe.de">https://rewe.de</a>
    </p>
    <p>
        <a href="https://rewe.de/">https://rewe.de/</a>
    </p>
    <p>
        <a href="https://rewe.de/angebote">https://rewe.de/angebote</a>
    </p>
    <p>
        <a href="https://rewe.de/angebote/">https://rewe.de/angebote/</a>
    </p>

    <h2>5</h2>
    <p>
        <a href="rewe://coupons">rewe://coupons</a>
    </p>
    <p>
        <a href="rewe://coupons/BAD_ID">rewe://coupons/BAD_ID</a>
    </p>
    <p>
        <a href="rewe://coupons/baf60e24-2392-45e6-9a86-ccd1830e5912">rewe://coupons/baf60e24-2392-45e6-9a86-ccd1830e5912</a>
    </p>
    <p>
        <a href="rewe://coupons/74661f84-deb7-47c2-b004-5706426d0173">rewe://coupons/74661f84-deb7-47c2-b004-5706426d0173</a>
    </p>
    <p>
        <a href="rewe://bonusguthaben">rewe://bonusguthaben</a>
    </p>
    <p>
        <a href="rewe://bonusbooster">rewe://bonusbooster</a>
    </p>
    <p>
        <a href="rewe://angebote/bonuskategorie">rewe://angebote/bonuskategorie</a>
    </p>
    <p>
        <a href="rewe://bonusteilnahmeflow">rewe://bonusteilnahmeflow</a>
    </p>
    <p>
        <a href="rewe://onescan">rewe://onescan</a>
    </p>
    <p>
        <a href="rewe://haushaltskonto">rewe://haushaltskonto</a>
    </p>
    <p>
        <a href="rewe://pewe">rewe://pewe</a>
    </p>

    <h2>6</h2>
    <p>
        <a href="https://shop.rewe.de/c/milch">https://shop.rewe.de/c/milch</a>
    </p>
    <p>
        <a href="https://shop.rewe.de/c/nuesse">https://shop.rewe.de/c/nuesse</a>
    </p>
    <p>
        <a href="https://shop.rewe.de/c/obst-gemuese">https://shop.rewe.de/c/obst-gemuese</a>
    </p>
    <p>
        <a href="https://shop.rewe.de/c/regional">https://shop.rewe.de/c/regional</a>
    </p>
    <p>
        <a href="https://shop.rewe.de/c/vegane-vielfalt">https://shop.rewe.de/c/vegane-vielfalt</a>
    </p>
    
    <h2>7</h2>
    <p>
        <a href="rewe://coupons">rewe://coupons</a>
    </p>
    <p>
        <a href="rewe://vorteile">rewe://vorteile</a>
    </p>

  </div>
);

export default TestUrls;
